import NewImagesGrid from "../../UGCpage/grid/NewImageGrid"
import styles from "./ForthScreenPhoto.module.css"


const ForthScreenPhoto=()=>{
  


return <>
<NewImagesGrid/>
</>


}
export default ForthScreenPhoto